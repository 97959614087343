import { observable, computed, action } from 'mobx';
import axios from 'axios';
import GlobalStore from '../GlobalStore/GlobalStore';
import { Constants } from '../../utils/Constants';
import ICustomersModel from '../../models/ICustomersModel';
import ICustomer, { IOffering, ICurrency, ICustomerCurrency, ICustomerDataCenter } from '../../models/ICustomer';
import { ToastNotification } from '../../components/ToastNotification/ToastNotification';
import featureFlags from '../../utils/FeatureFlags/FeatureFlags';

export default class CustomersStore extends GlobalStore {

  @observable addDialogOpened: boolean = false;
  @observable editDialogOpened: boolean = false;
  @observable showCreateCustomer: boolean = false;
  @observable customerList: ICustomer[] = [];
  @observable customerStoredata: Array<ICustomersModel> = [];
  @observable offeringAndPackageData: IOffering[] = [];
  @observable currencyOptions: ICustomerCurrency[] = [];
  @observable dataCenter: ICustomerDataCenter[] = [{text: "US", value: "US"}, {text: "EU", value: "EU"}]
  @observable loading: boolean = false;
  @observable customerListClone: ICustomer[] = [];
  @observable currencyList: ICurrency[] = [];
  @observable loderText: string = '';
  notification?: ToastNotification | null;

  constructor() {
    super();
    sessionStorage.removeItem(Constants.SESSION_TOKEN);
    sessionStorage.removeItem(Constants.SESSION_ROLES);
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    }
    this.initializeToken();
    this.initializeFeatureFlag();
  }

  initializeToken() {
    axios.defaults.timeout = Constants.API_TIMEOUT;
    this.setSessionValuesFromQueryString();
    this.setRequestResponseConfig();
  }

  @action
  async initializeFeatureFlag() {
    await featureFlags.init();
  }

  @action
  setLoading(state: boolean) {
    this.loading = state;
  }

  @action
  setLoaderText(message: string) {
    this.loderText = message;
  }

  @computed get getLoading() {
    return this.loading;
  }

  @computed get getLoaderText() {
    return this.loderText;
  }

  @action
  showToast(message: string, severity: string, detailMessage: string) {
    this.notification!.notify(message, severity, detailMessage);
  }

  @computed get isModalOpen() {
    return this.addDialogOpened || this.editDialogOpened;
  }

  @action
  public setSessionValuesFromQueryString(): any {
    window.parent.postMessage(Constants.GET_ACCESSTOKEN, '*');
    const queryString = require('query-string');
    const parsedQueryString = queryString.parse(window.location.search);
    if (!!parsedQueryString.organizationid) {
      sessionStorage.setItem(
        Constants.SESSION_ORG_ID,
        parsedQueryString.organizationid
      );
    }
  }

  receiveMessage(postMessageEvent: any) {
    if (postMessageEvent.data.AccessToken) {
      sessionStorage.setItem(Constants.SESSION_TOKEN, postMessageEvent.data.AccessToken);
      window.parent.postMessage(Constants.APP_LOADED, '*');
    }
    if (postMessageEvent.data.Roles) {
      sessionStorage.setItem(Constants.SESSION_ROLES, postMessageEvent.data.Roles);
    }
    if (postMessageEvent.data.showCreateCustomerButtonByPrivilege) {
      this.showCreateCustomer = postMessageEvent.data.showCreateCustomerButtonByPrivilege;
    }

  }

  setRequestResponseConfig(): any {
    // Axios Request Config
    axios.interceptors.request.use(
      (config: any) => {
        config.headers = this.applyHeaders();
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    // Axios Response Config
    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        const response = error.response;
        if (response) {
          if (response.status === 401 && error.config) {
            try {
              // Call parent for the renewal of the token
              window.parent.postMessage(Constants.RENEW_TOKEN, '*');
            } catch (authError) {
              return Promise.reject(error);
            }
            // Retry the original request
            error.config.__isRetryRequest = true;
            error.config.__retryCount = error.config.__retryCount || 0;
            if (error.config.__retryCount >= 15) {
              // Reject with the error
              return Promise.reject(error);
            }
            error.config.__retryCount += 1;
            error.config.headers.Authorization =
              'Bearer ' + sessionStorage.getItem(Constants.SESSION_TOKEN);
            return axios(error.config);
          }
          return response;
        }
      }
    );
  }

  @action
  public loadOfferingAndPackages(loadingRequired: boolean = false) {
    if (loadingRequired) {
      this.setLoading(true);
    }
    let roletags = sessionStorage.getItem(Constants.SESSION_ROLES);
    if (roletags != null) {
      roletags = JSON.parse(roletags);
    }
    let url =
      this.envVariables.BASE_API_GATEWAY_URL +
      '/api/CreateCustomerOrg/OfferingsAndPackage';
    return axios({
      url: url,
      method: 'POST',
      data: roletags
    }).then(response => {
      if (response.status === 200 && response.data) {
        this.offeringAndPackageData = response.data.Result;
        if (loadingRequired) {
          this.setLoading(false);
        }
      } else {
        this.offeringAndPackageData = [];
        if (loadingRequired) {
          this.setLoading(false);
        }
      }
    })
      .catch(error => {
        this.offeringAndPackageData = [];
        if (loadingRequired) {
          this.setLoading(false);
        }
      });
  }

  @action
  public loadCurrency(loadingRequired: boolean = false) {
    if (loadingRequired) {
      this.setLoading(true);
    }
    let roletags = sessionStorage.getItem(Constants.SESSION_ROLES);
    if (roletags != null) {
      roletags = JSON.parse(roletags);
    }

    let tempCurrencyObj: ICustomerCurrency[] = [];
    let url =
      this.envVariables.BASE_API_GATEWAY_URL +
      '/api/Currency';
    return axios({
      url: url,
      method: 'GET'
    }).then(response => {
      if (response.status === 200 && response.data) {
        this.currencyList = response.data.Result;
        this.currencyList.forEach(currency => {
          tempCurrencyObj.push({ text: currency.CurrencyShortName + '-' + currency.CurrencyLabel, value: currency.CurrencyIRI });
        });

        this.currencyOptions = tempCurrencyObj;
        if (loadingRequired) {
          this.setLoading(false);
        }
      } else {
        this.currencyOptions = [];
        if (loadingRequired) {
          this.setLoading(false);
        }
      }
    })
      .catch(error => {
        this.currencyOptions = [];
        if (loadingRequired) {
          this.setLoading(false);
        }
      });
  }

  @action
  public showModal(mode: 'add' | 'edit') {
    if (mode === 'add') {
      this.setLoaderText(Constants.LOADER_LOADING);
      this.loadOfferingAndPackages(true);
      if (featureFlags.portfolioCurrencyConfiguration && featureFlags.portfolioCurrencyConfiguration.length) {
        this.loadCurrency(true);
      }
      this.addDialogOpened = true;
      this.editDialogOpened = false;
    }

    if (mode === 'edit') {
      this.loadOfferingAndPackages();
      if (featureFlags.portfolioCurrencyConfiguration && featureFlags.portfolioCurrencyConfiguration.length) {
        this.loadCurrency(true);
      }
      this.addDialogOpened = false;
      this.editDialogOpened = true;
    }
  }

  @action
  public dismissModal() {
    this.editDialogOpened = false;
    this.addDialogOpened = false;
  }

  @computed get showCreateCustomerButton() {
    return this.showCreateCustomer;
  }

  @action
  public createCustomer(formdata?: any) {
    this.setLoading(true);
    return axios({
      url:
        this.envVariables.BASE_API_GATEWAY_URL +
        '/api/organizationservice/organizations/emptyOrganization',
      method: 'POST',
      data: formdata
    })
      .then(response => {
        if (response.status === 200 && response.data) {
          const savedData: ICustomer = response.data;
          this.appendCustomerInfo(savedData);
          this.setLoading(false);
          this.showToast(
            Constants.CUSTOMER_ADD_SUCCESS,
            Constants.TOAST_SEVERITY_SUCCESS,
            Constants.CUSTOMER_DETAIL_MESSAGE_ADD_SUCCESS
          );
        } else {
          this.setLoading(false);
          this.showToast(
            Constants.CUSTOMER_ADD_FAILURE,
            Constants.TOAST_SEVERITY_CRITICAL,
            Constants.CUSTOMER_DETAIL_MESSAGE_ADD_FAILURE
          );
        }
      })
      .catch(error => {
        this.setLoading(false);
        this.showToast(
          Constants.CUSTOMER_ADD_FAILURE,
          Constants.TOAST_SEVERITY_CRITICAL,
          Constants.CUSTOMER_DETAIL_MESSAGE_ADD_FAILURE
        );
      });
  }

  @action
  public editCustomer(customerId: string, formdata?: any) {
    this.setLoading(true);
    return axios({
      url:
        this.envVariables.BASE_API_GATEWAY_URL +
        `/api/organizationservice/organizations/edit/${customerId}`,
      method: 'POST',
      data: formdata
    })
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          const savedData: ICustomer = response.data;
          this.updateCustomerInfo(savedData);
          this.dismissModal();
          this.setLoading(false);
          this.showToast(
            Constants.CUSTOMER_UPDATE_SUCCESS,
            Constants.TOAST_SEVERITY_SUCCESS,
            Constants.CUSTOMER_DETAIL_MESSAGE_UPDATE_SUCCESS
          );
        } else {
          this.dismissModal();
          this.setLoading(false);
          this.showToast(
            Constants.CUSTOMER_UPDATE_FAILURE,
            Constants.TOAST_SEVERITY_CRITICAL,
            Constants.CUSTOMER_DETAIL_MESSAGE_UPDATE_FAILURE
          );
        }
      })
      .catch((error: any) => {
        this.dismissModal();
        this.setLoading(false);
        this.showToast(
          Constants.CUSTOMER_UPDATE_FAILURE,
          Constants.TOAST_SEVERITY_CRITICAL,
          Constants.CUSTOMER_DETAIL_MESSAGE_UPDATE_FAILURE
        );
      });
  }

  @action
  getCustomerList = () => {
    this.setLoading(true);
    let url =
      this.envVariables.BASE_API_GATEWAY_URL +
      '/api/organizationservice/organizations/details';
    axios({
      url: url,
      method: 'GET'
    })
      .then(response => {
        if (response.status === 200 && response.data) {
          this.customerList = response.data;
          this.customerListClone = this.customerList;
          this.setLoading(false);
        } else {
          this.setLoading(false);
        }
      })
      .catch(error => {
        this.setLoading(false);
      });
  }

  @action
  getCustomerLogo = (customerId: string) => {
    this.setLoading(true);
    let url =
      this.envVariables.BASE_API_GATEWAY_URL +
      `/api/CustomerImage/customerorglogo/${customerId}`;
    axios({
      url: url,
      method: 'GET'
    })
      .then(response => {
        if (response.data) {
          this.updateImageInCustomerInfo(response.data.Result, customerId);
          this.setLoading(false);
        } else {
          this.setLoading(false);
        }
      })
      .catch(error => {
        this.setLoading(false);
      });
  }

  updateImageInCustomerInfo(data: any, customerId: string) {
    const index = this.customerList.findIndex(
      res => res.customerId === customerId
    );
    this.customerList[index].customerLogo = data;
  }

  @action
  updateCustomerInfo = (customerInfo: ICustomer) => {
    const index = this.customerList.findIndex(
      res => res.customerId === customerInfo.customerId
    );
    this.customerList[index] = customerInfo;
  }

  @action
  appendCustomerInfo = (customerInfo: ICustomer) => {
    this.customerList.push(customerInfo);
  }

  private applyHeaders() {
    const apiToken = sessionStorage.getItem(Constants.SESSION_TOKEN);
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + apiToken,
      'Ocp-Apim-Subscription-Key': this.envVariables.OCP_APIM_SUBSCRIPTION_KEY,
      OrganizationId: sessionStorage.getItem(Constants.SESSION_ORG_ID) || ''
    };
  }
}
